import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c7b468dc = () => interopDefault(import('../pages/AddCredential.vue' /* webpackChunkName: "pages/AddCredential" */))
const _28035b0f = () => interopDefault(import('../pages/Connections.vue' /* webpackChunkName: "pages/Connections" */))
const _cb4144e6 = () => interopDefault(import('../pages/Credential.vue' /* webpackChunkName: "pages/Credential" */))
const _be1c6708 = () => interopDefault(import('../pages/credential-offer.vue' /* webpackChunkName: "pages/credential-offer" */))
const _5ee974f2 = () => interopDefault(import('../pages/CredentialRequest.vue' /* webpackChunkName: "pages/CredentialRequest" */))
const _95901594 = () => interopDefault(import('../pages/Credentials.vue' /* webpackChunkName: "pages/Credentials" */))
const _49015495 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _77c8e24a = () => interopDefault(import('../pages/InitiateIssuance.vue' /* webpackChunkName: "pages/InitiateIssuance" */))
const _0d2740b2 = () => interopDefault(import('../pages/IssuanceError.vue' /* webpackChunkName: "pages/IssuanceError" */))
const _15e3b6ba = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _a7a552e0 = () => interopDefault(import('../pages/nfts/index.vue' /* webpackChunkName: "pages/nfts/index" */))
const _a1f96d58 = () => interopDefault(import('../pages/ReceiveCredential.vue' /* webpackChunkName: "pages/ReceiveCredential" */))
const _f8f498be = () => interopDefault(import('../pages/ScanQRCode.vue' /* webpackChunkName: "pages/ScanQRCode" */))
const _26179a9c = () => interopDefault(import('../pages/Settings/index.vue' /* webpackChunkName: "pages/Settings/index" */))
const _d8ed425c = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _4d8aa153 = () => interopDefault(import('../pages/Settings/data/index.vue' /* webpackChunkName: "pages/Settings/data/index" */))
const _2d1b537c = () => interopDefault(import('../pages/Settings/Ecosystems/index.vue' /* webpackChunkName: "pages/Settings/Ecosystems/index" */))
const _21ab0142 = () => interopDefault(import('../pages/verify/ScanNFT.vue' /* webpackChunkName: "pages/verify/ScanNFT" */))
const _520a6e5a = () => interopDefault(import('../pages/Settings/Ecosystems/dns.vue' /* webpackChunkName: "pages/Settings/Ecosystems/dns" */))
const _0740ab8e = () => interopDefault(import('../pages/Settings/Ecosystems/ebsi-essif.vue' /* webpackChunkName: "pages/Settings/Ecosystems/ebsi-essif" */))
const _83f3abba = () => interopDefault(import('../pages/Settings/Ecosystems/gaia-x.vue' /* webpackChunkName: "pages/Settings/Ecosystems/gaia-x" */))
const _490d3fd9 = () => interopDefault(import('../pages/Settings/Ecosystems/iota.vue' /* webpackChunkName: "pages/Settings/Ecosystems/iota" */))
const _3a3f5709 = () => interopDefault(import('../pages/Settings/Ecosystems/key.vue' /* webpackChunkName: "pages/Settings/Ecosystems/key" */))
const _df3e3778 = () => interopDefault(import('../pages/Settings/data/key/Import.vue' /* webpackChunkName: "pages/Settings/data/key/Import" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _024f4b77 = () => interopDefault(import('../pages/nfts/_chain/index.vue' /* webpackChunkName: "pages/nfts/_chain/index" */))
const _6d5080c2 = () => interopDefault(import('../pages/nfts/_chain/_id.vue' /* webpackChunkName: "pages/nfts/_chain/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/AddCredential",
    component: _c7b468dc,
    name: "AddCredential"
  }, {
    path: "/Connections",
    component: _28035b0f,
    name: "Connections"
  }, {
    path: "/Credential",
    component: _cb4144e6,
    name: "Credential"
  }, {
    path: "/credential-offer",
    component: _be1c6708,
    name: "credential-offer"
  }, {
    path: "/CredentialRequest",
    component: _5ee974f2,
    name: "CredentialRequest"
  }, {
    path: "/Credentials",
    component: _95901594,
    name: "Credentials"
  }, {
    path: "/Home",
    component: _49015495,
    name: "Home"
  }, {
    path: "/InitiateIssuance",
    component: _77c8e24a,
    name: "InitiateIssuance"
  }, {
    path: "/IssuanceError",
    component: _0d2740b2,
    name: "IssuanceError"
  }, {
    path: "/Login",
    component: _15e3b6ba,
    name: "Login"
  }, {
    path: "/nfts",
    component: _a7a552e0,
    name: "nfts"
  }, {
    path: "/ReceiveCredential",
    component: _a1f96d58,
    name: "ReceiveCredential"
  }, {
    path: "/ScanQRCode",
    component: _f8f498be,
    name: "ScanQRCode"
  }, {
    path: "/Settings",
    component: _26179a9c,
    name: "Settings"
  }, {
    path: "/verify",
    component: _d8ed425c,
    name: "verify"
  }, {
    path: "/Settings/data",
    component: _4d8aa153,
    name: "Settings-data"
  }, {
    path: "/Settings/Ecosystems",
    component: _2d1b537c,
    name: "Settings-Ecosystems"
  }, {
    path: "/verify/ScanNFT",
    component: _21ab0142,
    name: "verify-ScanNFT"
  }, {
    path: "/Settings/Ecosystems/dns",
    component: _520a6e5a,
    name: "Settings-Ecosystems-dns"
  }, {
    path: "/Settings/Ecosystems/ebsi-essif",
    component: _0740ab8e,
    name: "Settings-Ecosystems-ebsi-essif"
  }, {
    path: "/Settings/Ecosystems/gaia-x",
    component: _83f3abba,
    name: "Settings-Ecosystems-gaia-x"
  }, {
    path: "/Settings/Ecosystems/iota",
    component: _490d3fd9,
    name: "Settings-Ecosystems-iota"
  }, {
    path: "/Settings/Ecosystems/key",
    component: _3a3f5709,
    name: "Settings-Ecosystems-key"
  }, {
    path: "/Settings/data/key/Import",
    component: _df3e3778,
    name: "Settings-data-key-Import"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }, {
    path: "/nfts/:chain",
    component: _024f4b77,
    name: "nfts-chain"
  }, {
    path: "/nfts/:chain/:id",
    component: _6d5080c2,
    name: "nfts-chain-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
